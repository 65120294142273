"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var base_xx_1 = require("./base-xx");
var string_bytes_1 = require("./string-bytes");
var isString = function (val) { return typeof val === 'string' || val instanceof String; };
var isUint8Array = function (val) { return val instanceof Uint8Array; };
var isTRawStringInDiscriminator = function (_) { return false; };
exports._fromIn = function (inValue) {
    if (isString(inValue))
        return base_xx_1.base58Decode(inValue);
    if (isUint8Array(inValue))
        return inValue;
    return Uint8Array.from(inValue);
};
exports._fromRawIn = function (inValue) {
    if (isTRawStringInDiscriminator(inValue))
        throw new Error('');
    if (isString(inValue))
        return string_bytes_1.stringToBytes(inValue);
    if (isUint8Array(inValue))
        return inValue;
    return Uint8Array.from(inValue);
};
