"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../utils");
var constants_1 = require("../../constants");
var getAssetIdList = utils_1.switchTransactionByType((_a = {},
    _a[constants_1.NAME_MAP.transfer] = function (tx) { return [tx.assetId, tx.feeAssetId]; },
    _a[constants_1.NAME_MAP.burn] = function (tx) { return [tx.assetId]; },
    _a[constants_1.NAME_MAP.reissue] = function (tx) { return [tx.assetId]; },
    _a[constants_1.NAME_MAP.exchange] = function (tx) { return Array.from(new Set([
        tx.order1.assetPair.amountAsset,
        tx.order1.assetPair.priceAsset,
        tx.order1.version === 3 ? tx.order1.matcherFeeAssetId : null,
        tx.order2.version === 3 ? tx.order2.matcherFeeAssetId : null
    ])); },
    _a[constants_1.NAME_MAP.massTransfer] = function (tx) { return [tx.assetId]; },
    _a[constants_1.NAME_MAP.setAssetScript] = function (tx) { return [tx.assetId]; },
    _a[constants_1.NAME_MAP.sponsorship] = function (tx) { return [tx.assetId]; },
    _a[constants_1.NAME_MAP.invoke] = function (tx) { return __spread((tx.payment || []).map(utils_1.prop('assetId')), [tx.feeAssetId]); },
    _a[constants_1.NAME_MAP.updateAsset] = function (tx) { return [tx.assetId]; },
    _a));
function default_1(tx) {
    // @ts-ignore
    var idList = utils_1.toArray(tx).reduce(function (acc, tx) { return acc.concat(getAssetIdList(tx) || []); }, []).filter(function (x) { return x != null; });
    return idList;
}
exports.default = default_1;
