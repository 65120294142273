"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var param_1 = require("./param");
var Utf8_1 = require("../libs/Utf8");
exports.stringToBytes = function (str, encoding) {
    if (encoding === void 0) { encoding = 'utf8'; }
    if (encoding === 'utf8') {
        return Utf8_1.strToUtf8Array(str);
    }
    else if (encoding === 'raw') {
        return Uint8Array.from(__spread(str).map(function (c) { return c.charCodeAt(0); }));
    }
    else {
        throw new Error("Unsupported encoding " + encoding);
    }
};
exports.bytesToString = function (bytes, encoding) {
    if (encoding === void 0) { encoding = 'utf8'; }
    if (encoding === 'utf8') {
        return Utf8_1.utf8ArrayToStr(Array.from(param_1._fromIn(bytes)));
    }
    else if (encoding === 'raw') {
        return Array.from(param_1._fromIn(bytes))
            .map(function (byte) { return String.fromCharCode(byte); })
            .join('');
    }
    else {
        throw new Error("Unsupported encoding " + encoding);
    }
};
/**
 * Converts each character to byte
 */
exports.binaryStringToBytes = function (str) {
    return Uint8Array.from(__spread(str).map(function (c) { return c.charCodeAt(0); }));
};
/**
 * Reads each byte as individual character
 */
exports.bytesToBinaryString = function (bytes) {
    return Array.from(param_1._fromIn(bytes))
        .map(function (byte) { return String.fromCharCode(byte); })
        .join('');
};
