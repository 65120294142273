import { makeAutoObservable } from "mobx";
import RootStore from "./RootStore";
import puzzleNodeApiService from "@src/services/puzzleNodeApiService";
import BN from "@src/utils/BN";

class VotingStore {
  public readonly rootStore: RootStore;

  loading = false;
  setLoading = (value: boolean) => (this.loading = value);

  public total: BN | null = null;
  setTotal = (value: BN) => (this.total = value);

  public voted: BN | null = null;
  private setVoted = (value: BN | null) => (this.voted = value);

  public myVote: BN | null = null;
  private setMyVote = (value: BN | null) => (this.myVote = value);

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    Promise.all([this.refresh()]);
  }

  refresh = async (force = false) => {
    if (!force && this.loading) return;

    this.setLoading(true);

    const voting = await puzzleNodeApiService.getVoting(this.rootStore.accountStore.address);

    this.setTotal(new BN(voting.total));
    this.setVoted(new BN(voting.voted));
    this.setMyVote(new BN(voting.myVote));

    this.setLoading(false);
  }
}

export default VotingStore;
