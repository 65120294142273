
import React, { useMemo } from "react";
import { makeAutoObservable, reaction } from "mobx";
import { RootStore, useStores } from "@stores";
import { useVM } from "@src/hooks/useVM";
import BN from "@src/utils/BN";
import { FEATURE_VOTING_ADDRESS } from "@src/constants";
import { getExplorerLink } from "@src/utils/chainUtil";

interface IProps {
  children: React.ReactNode;
}

const ctx = React.createContext<VotingVM | null>(null);

export const VotingVMProvider: React.FC<IProps> = ({ children }) => {
  const rootStore = useStores();
  const store = useMemo(() => new VotingVM(rootStore), [rootStore]);
  return <ctx.Provider value={store}>{children}</ctx.Provider>;
};

export const useVotingVM = () => useVM(ctx);

class VotingVM {
  loading: boolean = false;
  private _setLoading = (l: boolean) => (this.loading = l);

  public total: BN | null = null;
  public setTotal = (value: BN | null) => (this.total = value);

  public voted: BN | null = null;
  private setVoted = (value: BN | null) => (this.voted = value);

  public myVote: BN | null = null;
  private setMyVote = (value: BN | null) => (this.myVote = value);

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
    reaction(
      () => this.rootStore.votingStore.loading,
      (loading) => this.updateVoting(loading)
    );
    reaction(
      () => this.rootStore.accountStore.address,
      () => this.refresh()
    );
    this.updateVoting(false);
  }

  get canVoteFor(): boolean {
    return this.myVote === null || this.myVote.isZero();
  }

  get canRetractVote(): boolean {
    return !this.canVoteFor;
  }

  voteFor = async () => {
    if (!this.canVoteFor) return;

    this._setLoading(true);
    const { accountStore, notificationStore } = this.rootStore;

    await accountStore
      .invoke({
        dApp: FEATURE_VOTING_ADDRESS,
        payment: [],
        call: {
          function: "vote",
          args: [
            { type: "integer", value: 23 },
          ]
        }
      })
      .then((txId) => {
        if (txId !== null) {
          notificationStore.notify(
            "",
            {
              type: "success",
              title: `Vote was successfully applied`,
              link: getExplorerLink(txId),
              linkTitle: "View on Explorer",
            }
          );
        }
      })
      .catch((e) => {
        notificationStore.notify(e.message ?? JSON.stringify(e), {
          type: "error",
          title: "Transaction is not completed",
        });
      })
      .then(() => this.refresh())
      .finally(() => this._setLoading(false))
  }

  retractVote = async () => {
    if (!this.canRetractVote) return;

    this._setLoading(true);
    const { accountStore, notificationStore } = this.rootStore;

    await accountStore
      .invoke({
        dApp: FEATURE_VOTING_ADDRESS,
        payment: [],
        call: {
          function: "retractVote",
          args: [
            { type: "integer", value: 23 },
          ]
        }
      })
      .then((txId) => {
        if (txId !== null) {
          notificationStore.notify(
            "",
            {
              type: "success",
              title: `Vote was successfully retracted`,
              link: getExplorerLink(txId),
              linkTitle: "View on Explorer",
            }
          );
        }
      })
      .catch((e) => {
        notificationStore.notify(e.message ?? JSON.stringify(e), {
          type: "error",
          title: "Transaction is not completed",
        });
      })
      .then(() => this.refresh())
      .finally(() => this._setLoading(false))
  }

  private refresh = async () => {
    this.rootStore.votingStore.refresh(true);
  }

  private updateVoting = async (loading: boolean) => {
    if (loading) return;

    const votingStore = this.rootStore.votingStore;
    this.setTotal(votingStore.total);
    this.setVoted(votingStore.voted);
    this.setMyVote(votingStore.myVote);
  }
}