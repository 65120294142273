// eslint-disable-next-line
export default {
  icons: {
    logo: require("@src/assets/logo.svg").default,
    arrowDown: require("@src/assets/icons/arrowDown.svg").default,
    limitOrderArrow: require("@src/assets/icons/limitOrderArrow.svg").default,
    arrowDownWithBorder: require("@src/assets/icons/lightArrowWithBorder.svg").default,
    reset: require("@src/assets/icons/lightReset.svg").default
  }
};
