import { CHAIN_ID, WX_URL } from "@src/constants";
import axios from "axios";

interface IRates {
  data: {
    rate: number
  }
}

const wxService = {
  getPrices: async (assets: string[]): Promise<number[]> => {
    const url = "/api/v1/rates";
    const pairs = CHAIN_ID === "W"
      ? assets.map(x => x + "/USD")
      : ["WAVES/USD", "7scqyYoVsNrpWbTAc78eRqNVcYLxMPzZs8EQfX7ruJAg/USD"];
    const { data } = await axios.post(`${WX_URL}${url}`, { pairs: pairs });
    const rates = data.data as IRates[];
    return rates.map(x => x.data.rate);
  }
}

export default wxService;