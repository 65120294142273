import { Observer, observer } from "mobx-react-lite";
import { VotingVMProvider } from "./VotingVM";
import styled from "@emotion/styled";
import { Column } from "@src/components/Flex";
import Layout from "@src/components/Layout";
import SizedBox from "@src/components/SizedBox";
import Text from "@components/Text";
import VotingDescription from "./VotingDescription";
import VotingOptions from "./VotingOptions";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 16px;
  width: 100%;
  min-height: 100%;
  max-width: calc(1160px + 32px);
  margin-bottom: 24px;
  margin-top: 40px;
  text-align: left;
  @media (min-width: 880px) {
    margin-top: 56px;
  }
`;

const Body = styled.div`
  width: 100%;
  display: flex;
  @media (min-width: 880px) {
    grid-template-columns: auto 45ch;
    column-gap: 40px;
  }
`;

const MainBlock = styled.div`
  width: 100%;
  @media (min-width: 880px) {
    width: 100%;
  }
`;
const RightBlockMobile = styled(Column)`
  width: 100%;
  @media (min-width: 880px) {
    display: none;
  }
`;
const RightBlock = styled(Column)`
  width: 65%;
  display: none;
  @media (min-width: 880px) {
    display: flex;
  }
`;

const AdaptiveText = styled(Text)`
  @media (min-width: 880px) {
    max-width: 100%;
  }
`;

const VotingImpl: React.FC = () => {
  return (
    <Layout>
      <Observer>
        {() => (
          <Root>
            <Text weight={500} size="large">
              Feature voting
            </Text>
            <SizedBox height={8} />
            <AdaptiveText fitContent textAlign="left" type="secondary">
              Vote for the features with leased WAVES and participate in changing the way the blockchain works.
            </AdaptiveText>
            <AdaptiveText fitContent textAlign="left" type="secondary">
              Puzzle Node votes for a feature with a power proportional to the stake of lessors voted in favour.
            </AdaptiveText>
            <SizedBox height={32} />
            <Text weight={500} size="large">
              Feature 23 - Boost Block Reward
            </Text>
            <Body>
              <MainBlock>
                <VotingDescription />
                <SizedBox height={10} />
                <RightBlockMobile>
                  <VotingOptions />
                </RightBlockMobile>
              </ MainBlock>
              <RightBlock>
                <VotingOptions />
              </RightBlock>
            </Body>
          </Root>
        )}
      </Observer>
    </Layout>
  );
}

const Voting: React.FC = () => {
  return (
    <VotingVMProvider>
      <VotingImpl />
    </VotingVMProvider>
  );
};

export default observer(Voting);