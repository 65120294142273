import styled from "@emotion/styled";
import React from "react";
import Text from "@components/Text";
import Card from "@components/Card";
import { Column, Row } from "@src/components/Flex";
import { observer } from "mobx-react-lite";
import SizedBox from "@src/components/SizedBox";
import { Link } from "react-router-dom";
import { Anchor } from "@src/components/Anchor";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (min-width: 370px) {
    flex-direction: row;
  }
`;

const AdaptiveText = styled(Text)`
  @media (min-width: 880px) {
    max-width: 100%;
  }
`;

const VotingDescription: React.FC = () => {
  return (
    <Root>
      <Card>
        <Container>
          <Row alignItems="center">
            <Column justifyContent="space-between">
              <AdaptiveText>
                With feature activation and for the following 300000 blocks (around 7 months), the total block reward will be increased by a factor of 10.
              </AdaptiveText>
              <SizedBox height={4} />
              <AdaptiveText>
                This means that a total of 60 Waves will be generated per block, with 20 going to Waves miners, 20 still used for XTN buyback, and the remaining 20 sent to the Waves DAO treasury.
              </AdaptiveText>
              <SizedBox height={4} />
              <AdaptiveText>
                The total supply of WAVES will be increased by 18,000,000 WAVES for 7 months, that is ~15% increase of the total supply.
              </AdaptiveText>
              <SizedBox height={4} />
              <Anchor href="https://docs.google.com/document/d/18XQV5-5KnzNKciFhyL8w99KdvwDEvSip59Zbo82y_Bw/edit?usp=sharing">
                <Text type="secondary">Read full proposal</Text>
              </Anchor>
              <SizedBox height={4} />
              <Anchor href="https://sasha35625.medium.com/waves-deflationary-future-1b735a2d03c5">
                <Text type="secondary">Read medium article</Text>
              </Anchor>
            </Column>
          </Row>
        </Container>
      </Card>
    </Root>
  );
};
export default observer(VotingDescription);
