import styled from "@emotion/styled";
import React from "react";
import SizedBox from "@components/SizedBox";
import LinkGroup from "@components/LinkGroup";
import Divider from "@components/Divider";
import Wallet from "@components/Wallet/Wallet";
import Scrollbar from "@components/Scrollbar";
import { Column } from "../Flex";
import { observer } from "mobx-react-lite";
import DarkMode from "@components/Header/DarkMode";
import { CHAIN_ID, ROUTES } from "@src/constants";

interface IProps {
  onClose: () => void;
  bannerClosed?: boolean;
  opened: boolean;
}

const Root = styled.div<{ bannerClosed: boolean; opened: boolean }>`
  z-index: 100;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  // top: ${({ bannerClosed }) => (bannerClosed ? 64 : 144)}px;
  top: 64px;
  left: 0;
  right: 0;
  //height: calc(100vh - ${({ bannerClosed }) => (bannerClosed ? 64 : 144)}px);
  height: calc(100vh - 64px);
  transition: 0.2s;
  overflow: hidden;

  ${({ opened }) => (!opened ? `height: 0px;` : "")}
  .menu-body {
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.white};
  }
`;

const WalletWrapper = styled.div`
  padding: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.primary100};
`;

const menuItems = [
  { name: "Lease WAVES", link: ROUTES.LEASE_WAVES, isExternalLink: false },
  { name: "Voting", link: ROUTES.VOTING, isExternalLink: false },
  { name: "Trade", link: "https://puzzleswap.org/", isExternalLink: true },
  { name: "NFT", link: "https://puzzlemarket.org/", isExternalLink: true },
  { name: "Lend", link: "https://lend.puzzleswap.org/", isExternalLink: true },
];

const communityMenu = [
  { name: `Lease on ${CHAIN_ID === "W" ? "Testnet" : "Mainnet"}`, link: CHAIN_ID === "W" ? "https://lease-test.puzzleswap.org/" : "https://lease.puzzleswap.org/", isExternalLink: true },
  { name: "Telegram chat", link: "https://t.me/PuzzleNode", isExternalLink: true },
  { name: "Official Twitter", link: "https://x.com/PuzzleNode", isExternalLink: true },
  { name: "GitHub repository", link: "https://github.com/puzzlenetwork/puzzle-node-contracts", isExternalLink: true },
];

const MobileMenu: React.FC<IProps> = ({ bannerClosed = false, opened, onClose }) => {
  return (
    <Root {...{ bannerClosed, opened }}>
      <div className="menu-body">
        <Divider />
        <Scrollbar style={{ margin: 24, marginBottom: 0 }}>
          <Column crossAxisSize="max" style={{ maxHeight: "50vh" }}>
            <LinkGroup onClick={onClose} title="" links={menuItems} />
            <SizedBox height={24} />
            <LinkGroup title="" links={communityMenu} />
            <SizedBox height={24} width={1} />
          </Column>
        </Scrollbar>
        <DarkMode style={{ margin: 16 }} />
        <WalletWrapper>
          <Wallet />
        </WalletWrapper>
      </div>
    </Root>
  );
};
export default observer(MobileMenu);
