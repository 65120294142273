import styled from "@emotion/styled";
import { Column } from "@components/Flex";
import Header from "@components/Header";
import LoginScreen from "@screens/LoginScreen";
import { Route, Routes } from "react-router-dom";
import NotFound from "@screens/NotFound";
import { ROUTES } from "./constants";
import Leasing from "@src/screens/Leasing";
import Voting from "./screens/Voting/Voting";

const Root = styled(Column)`
  width: 100%;
  align-items: center;
  background: ${({ theme }) => theme.colors.primary50};
  min-height: 100vh;
`;
const MobileSpace = styled.div`
  height: 56px;
  @media (min-width: 880px) {
    display: none;
  }
`;

const App: React.FC = () => {
  return (
    <Root>
      <LoginScreen />
      <Header />
      <Routes>
        <Route path={ROUTES.LEASE_WAVES} element={<Leasing />} />
        <Route path={ROUTES.VOTING} element={<Voting />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <MobileSpace />
    </Root>
  );
}

export default App;
