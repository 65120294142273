import React from "react";
import SizedBox from "@components/SizedBox";
import { Row } from "@components/Flex";
import Text from "@components/Text";
import Button from "@components/Button";
import { useLeasingVM } from "@screens/Leasing/LeasingVM";
import { observer } from "mobx-react-lite";
import Loading from "@components/Loading";
import TokenSelect from "@src/components/TokenInput/TokenSelect";
import BN from "@src/utils/BN";
import tokenLogos from "@src/constants/tokenLogos";
import { TOKENS_BY_SYMBOL } from "@src/constants";
import { useStores } from "@src/stores";

interface IProps { }

const Cancel: React.FC<IProps> = () => {
  const vm = useLeasingVM();
  const { accountStore } = useStores();

  if (accountStore.address === null) {
    return (<Text textAlign="center" type="secondary">Connect your wallet to see active leases</Text>);
  }

  const atLeastOneLease = vm.leaseItems !== null && vm.leaseItems.length > 0

  return (
    <>
      {atLeastOneLease ? (
        <>
          <Row alignItems="center" justifyContent="space-between">
            <Text type="secondary">Transaction fee</Text>
            <Text textAlign="right">0.001 WAVES</Text>
          </Row>
          <SizedBox height={24} />
        </>) : <></>}
      {atLeastOneLease ? vm.leaseItems?.map(x => (
        <>
          <Row>
            <TokenSelect
              logo={tokenLogos.LEASEDWAVES}
              symbol={TOKENS_BY_SYMBOL.WAVES.symbol}
              balance={BN.formatUnits(x.amount, vm.wavesToken.decimals).toFormat(2)}
            />
            {!vm.loading ? (
              <Button fixed onClick={() => vm.cancelLease(x)}>
                Cancel Lease
              </Button>
            ) : (
              <Button disabled fixed>
                Transaction in progress <Loading />
              </Button>
            )}
          </Row>
          <SizedBox height={8} />
        </>
      )) : <Text textAlign="center" type="secondary">You don't have any active leases</Text>}
    </>
  );
};
export default observer(Cancel);
