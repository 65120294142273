import { AGGREGATOR_URL, CHAIN_ID } from "@src/constants";
import axios from "axios";

const aggregatorService = {
  getAvailableAssetsIds: async (): Promise<string[]> => {
    if (CHAIN_ID === "W") {
      const url = "/aggregator/getAvailableAssetsIds";
      const { data } = await axios.get(`${AGGREGATOR_URL}${url}`)
      return data.availableAssetsIds;
    } else if (CHAIN_ID === "T") {
      return [
        "WAVES",
        "mcGWqCMsSC1mw1xrQJVnH4o56o3FArJ9QoThfAudwqM",
        "6fQRaL3L25nGqevXT8kxwjBS28fkBErrJ69JYcj7qKgW",
        "25FEqEjRkqK6yCkiT7Lz6SAYz7gUFCtxfCChnrVFD5AT",
      ];
    } else {
      return [
        "WAVES",
        "9HFED3ugWZpwk6AZd2BzXWJq1jKioeHDsQ6wUqWhxmHa",
        "6efh1dyXTxXiogLwVs8ta7d4d3m9Nyfb4EYSs2C5BYrg",
        "AywZvp2EiQ7WAymEaL7HctNiBJTsPm8itGm8HT3YDaWi",
      ];
    }
  }
}

export default aggregatorService;