"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FIELDS = ['amount', 'matcherFee', 'price', 'minSponsoredAssetFee', 'fee', 'quantity', 'sellMatcherFee', 'buyMatcherFee'];
function default_1(data) {
    return JSON.stringify(data, function (key, value) {
        if (FIELDS.includes(key) && value) {
            return "!" + value + "!";
        }
        else if (key === 'value' && this['type'] === 'integer') {
            return "!" + value + "!";
        }
        else if (key === 'minSponsoredAssetFee' && value == null) {
            return null;
        }
        else {
            return value;
        }
    }, 0).replace(/"\!(-?\d+)\!"/g, '$1');
}
exports.default = default_1;
