import styled from "@emotion/styled";
import { Row, Column, IFlexProps } from "@src/components/Flex";
import SizedBox from "@src/components/SizedBox";
import Text from "@components/Text";
import SquareTokenIcon from "@src/components/SquareTokenIcon";
import { observer } from "mobx-react-lite";
import Img from "@src/components/Img";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import TokenSelectModal from "@src/components/TokensSelectModal";
import { useLeasingVM } from "../LeasingVM";
import Balance from "@src/entities/Balance";

interface IProps {
  asset: Balance;
  setAsset: (asset: Balance) => void;
  reset: () => void;
}

const Root = styled.div`
  disply: flex;
  flex-direction: row;
`;

const Selector = styled.div`
  display: flex;
  flex-direction: row;
  flex: auto;
`

const SelectorRoot = styled.div<IFlexProps>`
  display: flex;
  flex-direction: row;

  justify-content: start;
  align-items: center;
  height: fit-content;
  width: 100%;

  cursor: pointer;
  padding: 16px;
  background: ${({ theme }) => theme.colors.primary100};
  border-radius: 12px;
  height: 30px;
`

const Reset = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-left: 8px;
`

const SwapToAsset: React.FC<IProps> = (props) => {
  const theme = useTheme();
  const vm = useLeasingVM();
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <Root>
        <Row alignItems="center">
          <SelectorRoot onClick={() => setOpenModal(true)}>
            <Selector>
              <SquareTokenIcon size="small" src={props.asset.logo} />
              <SizedBox width={12} />
              <Column>
                <Text size="medium" type="secondary">
                  You receive
                </Text>
                <Text weight={500}>{props.asset.symbol}</Text>
              </Column>
            </Selector>
            <Row alignItems="center" mainAxisSize="fit-content">
              <Img src={theme.images.icons.arrowDownWithBorder} alt="arrow" />
            </Row>
          </SelectorRoot>
          <Reset onClick={() => props.reset()}>
            <Img src={theme.images.icons.reset} alt="arrow" />
          </Reset>
        </Row>
      </Root>

      <TokenSelectModal
        selectedTokenId={props.asset.assetId}
        visible={openModal}
        onSelect={props.setAsset}
        balances={vm.aggregatorAssets}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
}

export default observer(SwapToAsset);