"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var param_1 = require("../conversions/param");
var string_bytes_1 = require("../conversions/string-bytes");
exports.Seed = {
    isSeedWithNonce: function (val) {
        return val.nonce !== undefined;
    },
    toBinary: function (seed) {
        return exports.Seed.isSeedWithNonce(seed) ?
            { seed: exports.Seed.toBinary(seed.seed).seed, nonce: seed.nonce } :
            { seed: param_1._fromRawIn(seed), nonce: undefined };
    },
    toString: function (seed) {
        return string_bytes_1.bytesToString(exports.Seed.toBinary(seed).seed);
    },
};
