"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var string_bytes_1 = require("../conversions/string-bytes");
// @ts-ignore
var forgeMd5 = __importStar(require("node-forge/lib/md5"));
var concat_split_1 = require("./concat-split");
var encryption_1 = require("./encryption");
var base_xx_1 = require("../conversions/base-xx");
var hashing_1 = require("./hashing");
var random_1 = require("./random");
function strengthenPassword(password, rounds) {
    if (rounds === void 0) { rounds = 5000; }
    while (rounds--) {
        var bytes = string_bytes_1.stringToBytes(password);
        password = base_xx_1.base16Encode(hashing_1.sha256(bytes));
    }
    return password;
}
function evpKdf(passphrase, salt, output) {
    if (output === void 0) { output = 48; }
    var passPlusSalt = string_bytes_1.bytesToString(concat_split_1.concat(passphrase, salt), 'raw');
    var key = '';
    var final_key = key;
    while (final_key.length < output) {
        key = forgeMd5.create().update(key + passPlusSalt).digest().getBytes();
        final_key += key;
    }
    return final_key;
}
/**
 * Encrypts arbitrary utf8 string with utf8 password. Evp key deriving function is used to get encryption key and IV from
 * password. AES-CBC algorithm is used for encryption. Output format is base64 encoded OpenSSL
 * @param seed - utf8 string to encrypt
 * @param password - utf8 password
 * @param encryptionRounds - how many times password will be hashed. Default = 5000
 */
exports.encryptSeed = function (seed, password, encryptionRounds) {
    var passphrase = strengthenPassword(password, encryptionRounds);
    var salt = random_1.randomBytes(8);
    var key_iv = evpKdf(string_bytes_1.stringToBytes(passphrase, 'raw'), salt);
    var key = string_bytes_1.stringToBytes(key_iv.slice(0, 32), 'raw');
    var iv = string_bytes_1.stringToBytes(key_iv.slice(32), 'raw');
    var encrypted = encryption_1.aesEncrypt(string_bytes_1.stringToBytes(seed), key, 'CBC', iv);
    return base_xx_1.base64Encode(concat_split_1.concat(string_bytes_1.stringToBytes('Salted__'), salt, encrypted));
};
exports.decryptSeed = function (encryptedSeed, password, encryptionRounds) {
    var passphrase = strengthenPassword(password, encryptionRounds);
    var encBytes = base_xx_1.base64Decode(encryptedSeed);
    var salt = encBytes.slice(8, 16);
    var key_iv = evpKdf(string_bytes_1.stringToBytes(passphrase, 'raw'), salt);
    var key = string_bytes_1.stringToBytes(key_iv.slice(0, 32), 'raw');
    var iv = string_bytes_1.stringToBytes(key_iv.slice(32), 'raw');
    return string_bytes_1.bytesToString(encryption_1.aesDecrypt(encBytes.slice(16), key, 'CBC', iv));
};
