import BN from "@src/utils/BN";

export interface ILeaseItem {
  leaseId: string;
  recipient: string;
  amount: BN;
}

class LeaseItem implements ILeaseItem {
  public readonly leaseId: string;
  public readonly recipient: string;
  public readonly amount: BN;

  constructor(props: ILeaseItem) {
    this.leaseId = props.leaseId;
    this.recipient = props.recipient;
    this.amount = props.amount;
  }
}

export default LeaseItem;