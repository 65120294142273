import styled from "@emotion/styled";
import React from "react";
import Card from "@components/Card";
import { Row } from "@src/components/Flex";
import { observer } from "mobx-react-lite";
import { useStores } from "@src/stores";
import Button from "@src/components/Button";
import Loading from "@src/components/Loading";
import { useVotingVM } from "./VotingVM";
import SizedBox from "@src/components/SizedBox";
import Text from "@components/Text";
import BN from "@src/utils/BN";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (min-width: 370px) {
    flex-direction: row;
  }
`;

const VotingOptions: React.FC = () => {
  const vm = useVotingVM();
  const { accountStore } = useStores();

  const total = vm.total !== null
    ? `${vm.total.toFormat(0)} WAVES`
    : null;

  const voted = vm.voted !== null && vm.total !== null
    ? `${vm.voted.toFormat(0)} WAVES (${BN.ratioOf(vm.voted, vm.total).toFormat(2)} %)`
    : null;

  const myVote = vm.myVote !== null && vm.total !== null
    ? `${vm.myVote.toFormat(0)} WAVES (${BN.ratioOf(vm.myVote, vm.total).toFormat(2)} %)`
    : null;

  return (
    <Root>
      <Card>
        <Container>
          <Row alignItems="center">
            <Text nowrap type="secondary">
              Total
            </Text>
          </Row>
          <SizedBox height={16} />
          <Row alignItems="center">
            <Text nowrap textAlign="right">
              {total ?? "—"}
            </Text>
          </Row>
        </Container>
        <Container>
          <Row alignItems="center">
            <Text nowrap type="secondary">
              Voted
            </Text>
          </Row>
          <SizedBox height={16} />
          <Row alignItems="center">
            <Text nowrap textAlign="right">
              {voted ?? "—"}
            </Text>
          </Row>
        </Container>
        <Container>
          <Row alignItems="center">
            <Text nowrap type="secondary">
              My vote
            </Text>
          </Row>
          <SizedBox height={16} />
          <Row alignItems="center">
            <Text nowrap textAlign="right">
              {myVote ?? "—"}
            </Text>
          </Row>
        </Container>

        <SizedBox height={24} />
        <Row alignItems="center" justifyContent="space-between">
          <Text type="secondary">Transaction fee</Text>
          <Text textAlign="right">0.005 WAVES</Text>
        </Row>
        <SizedBox height={24} />
        {!vm.loading ? (
          accountStore.address === null ? (<Button
            fixed
            size="large"
            onClick={() => accountStore.setLoginModalOpened(true)}
          >
            Connect wallet
          </Button>) : (
            <Row>
              <Button fixed onClick={vm.voteFor} disabled={!vm.canVoteFor}>
                Vote for
              </Button>
              <SizedBox width={16} />
              <Button fixed onClick={vm.retractVote} disabled={!vm.canRetractVote}>
                Retract vote
              </Button>
            </Row>
          )
        ) : (
          <Button disabled fixed>
            Transaction in progress <Loading />
          </Button>
        )}
      </Card>
    </Root>
  );
};
export default observer(VotingOptions);
