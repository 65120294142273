import { PUZZLE_NODE_API_URL, CHAIN_ID } from "@src/constants";
import axios from "axios";

interface IVoting {
  total: number,
  voted: number,
  myVote: number,
}

const puzzleNodeApiService = {
  getVoting: async (address: string | null): Promise<IVoting> => {
    const url = "/puzzleNode/voting";
    const { data } = await axios.get(`${PUZZLE_NODE_API_URL}${url}?chainId=${CHAIN_ID}&address=${address}`);
    const voting = data as IVoting;
    return voting;
  }
}

export default puzzleNodeApiService;