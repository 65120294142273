import React from "react";
import styled from "@emotion/styled";
import Text from "@components/Text";
import SizedBox from "@components/SizedBox";
import Dropdown from "@components/Dropdown";

interface IProps { }

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const data = [
  {
    title: "Is leasing safe?",
    text: "Absolutely, when a user starts leasing, WAVES are locked and remained in user's wallet under his full control (they are not transferred to the node, they just remain unspendable until the lease is canceled by the lessor)."
  },
  {
    title: "What is the payout policy?",
    text: "The block reward, the transaction fees, WAVESDLP, MEV-arbitrage profit are shared as follows: 95% goes to the lessors, 2.5% goes to buyback PUZZLE from the market and distribute it to the stacking balances, 2.5% goes to the node maintainer."
  },
  {
    title: "What is the minimal amount of WAVES for leasing?",
    text: "There is no minimum amount for leasing."
  },
  {
    title: "What is the payouts frequency?",
    text: "Payouts are perfomed daily at 12:00 UTC. If no blocks were mined during the day, the payment will be postponed to the next day."
  },
  {
    title: "How long does it take for my lease to start counting for payouts?",
    text: "Lease activation time is 1000 blocks or ~16.7 hours."
  },
  {
    title: "What is MEV?",
    text: "Maximum Extractable Value (MEV) is the maximum profit that miner can make by including, excluding, and changing the order of transactions in a block. Our node uses the arbitrage, the profit of which is shared among lessors."
  },
  {
    title: "When I can cancel my lease?",
    text: "Lease cancellation is allowed at any time and is executed instantly."
  },
];

const FAQ: React.FC<IProps> = () => {
  return (
    <Root>
      <Text weight={500} type="secondary">
        FAQ
      </Text>
      <SizedBox height={8} />
      <Wrapper>
        {data.map(({ text, title }, index) => (
          <Dropdown key={`faq-${index}`} title={title}>
            {text}
          </Dropdown>
        ))}
      </Wrapper>
    </Root>
  );
};
export default FAQ;
