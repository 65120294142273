import Balance from "@src/entities/Balance";
import tokens from "./tokens.json";
import privateTokens from "./private-tokens.json";
import testnetTokens from "./testnet-tokens.json";
import tokenLogos from "@src/constants/tokenLogos";

export const ROUTES = {
  ROOT: "/",
  LEASE_WAVES: "/",
  VOTING: "/voting",
  NOT_FOUND: "/404",
  WALLET: "/wallet",
  TRANSFER: "/transfer",
};

export const WAVESDLP_ID = process.env.REACT_APP_WAVESDLP_ID as string;
export const L2MP_ID = process.env.REACT_APP_L2MP_ID as string;

export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID as "W" | "T" | "R";

export const TOKENS_LIST: Array<IToken> = Object.values(
  CHAIN_ID === "W" ? tokens : CHAIN_ID === "T" ? testnetTokens : privateTokens)
  .map((t) => ({
    ...t,
    logo: tokenLogos[t.symbol],
  }));

export const TOKENS_BY_SYMBOL: Record<string, IToken> = TOKENS_LIST.reduce(
  (acc, t) => ({ ...acc, [t.symbol]: t }),
  {}
);
export const TOKENS_BY_ASSET_ID: Record<string, IToken> = TOKENS_LIST.reduce(
  (acc, t) => ({ ...acc, [t.assetId]: t }),
  {}
);

export const NODE_URL = process.env.REACT_APP_NODE_URL as string;
export const EXPLORER_URL = process.env.REACT_APP_EXPLORER_URL as string;
export const AGGREGATOR_URL = "https://swapapi.puzzleswap.org";
export const PUZZLE_NODE_API_URL = process.env.REACT_APP_PUZZLE_NODE_API_URL as string;
export const WX_URL = "https://wx.network";

export const NODE_ADDRESS = process.env.REACT_APP_NODE_ADDRESS as string;
export const SETTINGS_ADDRESS = process.env.REACT_APP_SETTINGS_ADDRESS as string;
export const FEATURE_VOTING_ADDRESS = process.env.REACT_APP_FEATURE_VOTING_ADDRESS as string;
export const NODE_CALLER_ADDRESS = process.env.REACT_APP_NODE_CALLER_ADDRESS as string;
export const APR_REFERENCE_ADDRESS = process.env.REACT_APP_APR_REFERENCE_ADDRESS as string;
export const MEV_FUND_ADDRESS = process.env.REACT_APP_MEV_FUND_ADDRESS as string;

export const WavesAsset = new Balance({
  assetId: "WAVES",
  symbol: "WAVES",
  name: "WAVES",
  decimals: 8,
  logo: tokenLogos.WAVES
});

export const WavesDlpAsset = new Balance({
  assetId: WAVESDLP_ID,
  symbol: "WAVESDLP",
  name: "Waves DAO LP",
  decimals: 8,
  logo: tokenLogos.WAVESDLP
});

export interface IToken {
  assetId: string;
  name: string;
  symbol: string;
  decimals: number;
  startPrice?: number;
  description?: string;
  logo: string;
  category?: string[];
};
