"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var hashing_1 = require("./hashing");
var concat_split_1 = require("./concat-split");
/**
 *
 * @param rootHash
 * @param merkleProof
 * @param leafData
 */
function merkleVerify(rootHash, merkleProof, leafData) {
    var LEAF_PREFIX = Uint8Array.from([0]);
    var INTERNAL_NODE_PREFIX = Uint8Array.from([1]);
    if (rootHash.length !== 32)
        throw new Error('Failed to parse merkleProof: Invalid rootHash length');
    var leafHash = hashing_1.blake2b(concat_split_1.concat(LEAF_PREFIX, leafData));
    var proofsWithSide = [];
    var proofBytes = merkleProof.map(function (x) { return x; });
    while (proofBytes.length > 0) {
        var side = proofBytes[0] === 0 ? 'L' : 'R';
        var size = proofBytes[1];
        if (size < 1)
            throw new Error('Failed to parse merkleProof: Wrong hash size');
        var hash = proofBytes.slice(2, 2 + size);
        proofsWithSide.push([side, hash]);
        proofBytes = proofBytes.slice(2 + size);
    }
    var rootHashFromProof = proofsWithSide.reduce(function (acc, _a) {
        var _b = __read(_a, 2), side = _b[0], hash = _b[1];
        return hashing_1.blake2b(concat_split_1.concat(INTERNAL_NODE_PREFIX, side === 'R' ? concat_split_1.concat(hash, acc) : concat_split_1.concat(acc, hash)));
    }, leafHash);
    return rootHashFromProof.every(function (v, i) { return v === rootHash[i]; });
}
exports.merkleVerify = merkleVerify;
