"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
var _this = this;
Object.defineProperty(exports, "__esModule", { value: true });
// @ts-ignore
var pki = __importStar(require("node-forge/lib/pki"));
// @ts-ignore
var md = __importStar(require("node-forge/lib/md"));
// @ts-ignore
var md5 = __importStar(require("node-forge/lib/md5"));
// @ts-ignore
var util = __importStar(require("node-forge/lib/util"));
var base_xx_1 = require("../conversions/base-xx");
var string_bytes_1 = require("../conversions/string-bytes");
var sha3 = __importStar(require("js-sha3"));
// HACK. Monkey patch node-forge library to provide oids for missing hash algorithms
pki.oids['sha224'] = '2.16.840.1.101.3.4.2.4';
pki.oids['2.16.840.1.101.3.4.2.4'] = 'sha224';
pki.oids['sha3-224'] = '2.16.840.1.101.3.4.2.7';
pki.oids['2.16.840.1.101.3.4.2.7'] = 'sha3-224';
pki.oids['sha3-256'] = '2.16.840.1.101.3.4.2.8';
pki.oids['2.16.840.1.101.3.4.2.8'] = 'sha3-256';
pki.oids['sha3-384'] = '2.16.840.1.101.3.4.2.9';
pki.oids['2.16.840.1.101.3.4.2.9'] = 'sha3-384';
pki.oids['sha3-512'] = '2.16.840.1.101.3.4.2.10';
pki.oids['2.16.840.1.101.3.4.2.10'] = 'sha3-512';
exports.pemToBytes = function (pem) { return base_xx_1.base64Decode(pem.trim()
    .split(/\r\n|\n/)
    .slice(1, -1).join('')
    .trim()); };
var pemTypeMap = {
    rsaPrivateNonEncrypted: 'RSA PRIVATE KEY',
    rsaPublic: 'PUBLIC KEY',
};
exports.bytesToPem = function (bytes, type) {
    var header = "-----BEGIN " + pemTypeMap[type] + "-----\n";
    var footer = "-----END " + pemTypeMap[type] + "-----\n";
    var b64characters = base_xx_1.base64Encode(bytes);
    if (b64characters.length % 64 !== 0) {
        b64characters += ' '.repeat(64 - b64characters.length % 64);
    }
    var result = header;
    for (var i = 0; i < (b64characters.length / 64); i++) {
        result += b64characters.slice(i * 64, (i + 1) * 64) + '\n';
    }
    result += footer;
    return result;
};
exports.rsaKeyPairSync = function (bits, e) {
    var kp = pki.rsa.generateKeyPair(bits, e);
    return {
        rsaPrivate: exports.pemToBytes(pki.privateKeyToPem(kp.privateKey)),
        rsaPublic: exports.pemToBytes(pki.publicKeyToPem(kp.publicKey)),
    };
};
exports.rsaKeyPair = function (bits, e) { return __awaiter(_this, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve, reject) {
                pki.rsa.generateKeyPair(bits, e, function (err, kp) {
                    if (err)
                        reject(err);
                    resolve({
                        rsaPrivate: exports.pemToBytes(pki.privateKeyToPem(kp.privateKey)),
                        rsaPublic: exports.pemToBytes(pki.publicKeyToPem(kp.publicKey)),
                    });
                });
            })];
    });
}); };
var digestCreatorPlaceHolder = function (type) { return function () {
    throw new Error("Digest " + type + " is unsupported");
}; };
var MessageDigestAdapter = /** @class */ (function () {
    function MessageDigestAdapter(sha3Digest, algorithm) {
        this.sha3Digest = sha3Digest;
        this.algorithm = algorithm;
    }
    MessageDigestAdapter.makeCreator = function (sha3Hash, algorithmName) {
        return { create: function () { return new MessageDigestAdapter(sha3Hash.create(), algorithmName); } };
    };
    MessageDigestAdapter.prototype.update = function (msg, encoding) {
        this.sha3Digest.update(string_bytes_1.stringToBytes(msg, encoding == null ? 'raw' : encoding));
        return this;
    };
    MessageDigestAdapter.prototype.digest = function () {
        var bytes = Uint8Array.from(this.sha3Digest.digest());
        return util.createBuffer(string_bytes_1.bytesToString(bytes, 'raw'));
    };
    return MessageDigestAdapter;
}());
var digestMap = {
    'MD5': md5,
    'SHA1': md.algorithms.sha1,
    'SHA224': digestCreatorPlaceHolder('SHA224'),
    'SHA256': md.algorithms.sha256,
    'SHA384': md.algorithms.sha384,
    'SHA512': md.algorithms.sha512,
    'SHA3-224': MessageDigestAdapter.makeCreator(sha3.sha3_224, 'sha3-224'),
    'SHA3-256': MessageDigestAdapter.makeCreator(sha3.sha3_256, 'sha3-256'),
    'SHA3-384': MessageDigestAdapter.makeCreator(sha3.sha3_384, 'sha3-384'),
    'SHA3-512': MessageDigestAdapter.makeCreator(sha3.sha3_512, 'sha3-512'),
};
exports.rsaSign = function (rsaPrivateKey, message, digest) {
    if (digest === void 0) { digest = 'SHA256'; }
    var s = exports.bytesToPem(rsaPrivateKey, 'rsaPrivateNonEncrypted');
    var sk = pki.privateKeyFromPem(s);
    var _digest = digestMap[digest].create();
    _digest.update(string_bytes_1.bytesToString(message, 'raw'));
    return string_bytes_1.stringToBytes(sk.sign(_digest), 'raw');
};
exports.rsaVerify = function (rsaPublicKey, message, signature, digest) {
    if (digest === void 0) { digest = 'SHA256'; }
    var pk = pki.publicKeyFromPem(exports.bytesToPem(rsaPublicKey, 'rsaPublic'));
    var _digest = digestMap[digest].create();
    _digest.update(string_bytes_1.bytesToString(message), 'raw');
    return pk.verify(_digest.digest().getBytes(), string_bytes_1.bytesToString(signature, 'raw'));
};
