import { CHAIN_ID } from "@src/constants";
import dayjs from "dayjs";
import Countdown from "react-countdown";

interface IProps {
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

const PayoutCountdown: React.FunctionComponent = () => {
  const renderer = ({ hours, minutes, seconds, completed }: IProps) => {
    if (completed) {
      return <span>00:00:00</span>;
    } else {
      return <span>{hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</span>;
    }
  };

  const utcNow = dayjs.utc();
  const nextPayoutIn = CHAIN_ID === 'T'
    ? utcNow.startOf('hour').add(1, 'hours').toDate()
    : utcNow.hour() > 12
      ? utcNow.add(1, 'day').startOf('day').add(12, 'hours').toDate()
      : utcNow.startOf('day').add(12, 'hours').toDate();

  return (<Countdown date={nextPayoutIn} renderer={renderer} />);
}

export default PayoutCountdown;
