import { makeAutoObservable } from "mobx";
import RootStore from "./RootStore";
import BN from "@src/utils/BN";
import wxService from "@src/services/wxService";
import { L2MP_ID } from "@src/constants";

class PricesStore {
  public readonly rootStore: RootStore;

  pricesLoading = false;
  setPricesLoading = (value: boolean) => (this.pricesLoading = value);

  public wavesPrice: BN | null = null;
  setWavesPrice = (value: BN) => (this.wavesPrice = value);

  public l2mpPrice: BN | null = null;
  setL2mpPrice = (value: BN) => (this.l2mpPrice = value);

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    Promise.all([this.refreshSettings()]);
  }

  refreshSettings = async (force = false) => {
    if (!force && this.pricesLoading) return;

    const rates = await wxService.getPrices(["WAVES", L2MP_ID]);
    this.setWavesPrice(new BN(rates[0]));
    this.setL2mpPrice(new BN(rates[1]));

    this.setPricesLoading(true);
  }
}

export default PricesStore;