"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var ts_lib_crypto_1 = require("@waves/ts-lib-crypto");
var validateEthAddress = function (addr) {
    return addr != null && addr.length == 42;
};
function ethAddress2waves(ethAddress, chainId) {
    if (validateEthAddress(ethAddress)) {
        ethAddress = ethAddress.substr(2);
    }
    else {
        throw "Invalid ethereum address: " + ethAddress + " ";
    }
    var prefixBytes = new Uint8Array([0x01, chainId]);
    // Раскодировать HEX строку в байты (PK_HASH)
    var pkHashBytes = ts_lib_crypto_1.base16Decode(ethAddress);
    // Сделать чексумму CHECKSUM=keccak256(blake2b256([0x01, CHAIN_ID] + PK_HASH))
    var checksumBytes = new Uint8Array(__spread(prefixBytes, pkHashBytes));
    var checksum = ts_lib_crypto_1.keccak(ts_lib_crypto_1.blake2b(checksumBytes));
    // склеить [0x01, CHAIN_ID] (два байта) + PK_HASH (изначальные 20 байт) + CHECKSUM[1:4] (первые четыре байта чексуммы)
    var wavesBytes = new Uint8Array(__spread(prefixBytes, pkHashBytes.slice(0, 20), checksum.slice(0, 4)));
    // закодировать в base58
    var wavesAddress = ts_lib_crypto_1.base58Encode(wavesBytes);
    return wavesAddress;
}
exports.default = ethAddress2waves;
