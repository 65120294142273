"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ts_lib_crypto_1 = require("@waves/ts-lib-crypto");
function ethTxId2waves(ethTxId) {
    var id = ethTxId;
    if (ethTxId.startsWith('0x'))
        id = ethTxId.slice(2);
    return ts_lib_crypto_1.base58Encode(ts_lib_crypto_1.base16Decode(id));
}
exports.default = ethTxId2waves;
