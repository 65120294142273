"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var interface_1 = require("../crypto/interface");
var interface_2 = require("../crypto/interface");
exports.ChaidId = {
    toNumber: function (chainId) {
        return (typeof chainId === 'string' ? chainId.charCodeAt(0) : chainId);
    },
    isMainnet: function (chainId) {
        return exports.ChaidId.toNumber(chainId) === interface_1.MAIN_NET_CHAIN_ID;
    },
    isTestnet: function (chainId) {
        return exports.ChaidId.toNumber(chainId) === interface_2.TEST_NET_CHAIN_ID;
    },
};
