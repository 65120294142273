import styled from "@emotion/styled";
import React from "react";
import Text from "@components/Text";
import Card from "@components/Card";
import SizedBox from "@components/SizedBox";
import { Column, Row } from "@src/components/Flex";
import SquareTokenIcon from "@components/SquareTokenIcon";
import { observer } from "mobx-react-lite";
import BN from "@src/utils/BN";
import { useLeasingVM } from "./LeasingVM";
import tokenLogos from "@src/constants/tokenLogos";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (min-width: 370px) {
    flex-direction: row;
  }
`;
const MyBalances: React.FC = () => {
  const vm = useLeasingVM();
  const leased = vm.addressLeased
    ? vm.addressLeased.toFormat(2)
    : null;

  const available = vm.wavesBalance.balance !== undefined
    ? BN.formatUnits(vm.wavesBalance.balance, vm.wavesToken.decimals).toFormat(2)
    : null;

  return (
    <Root>
      <Text weight={500} type="secondary">
        My balances
      </Text>
      <SizedBox height={8} />
      <Container>
        <Row>
          <SquareTokenIcon src={tokenLogos.WAVES} size="small" />
          <SizedBox width={8} />
          <Column justifyContent="space-between">
            <Text nowrap type="secondary" size="small">
              Available to lease
            </Text>
            <Text nowrap weight={500}>
              {available ?? "—"}
            </Text>
          </Column>
        </Row>
        <SizedBox height={8} />
        <Row>
          <SquareTokenIcon src={tokenLogos.LEASEDWAVES} size="small" />
          <SizedBox width={8} />
          <Column justifyContent="space-between">
            <Text nowrap type="secondary" size="small">
              Leased
            </Text>
            <Text nowrap weight={500}>
              {leased ?? "—"}
            </Text>
          </Column>
        </Row>
      </Container>
    </Root>
  );
};
export default observer(MyBalances);
