import React from "react";
import Card from "@components/Card";
import SwitchButtons from "@components/SwitchButtons";
import { observer } from "mobx-react-lite";
import { useLeasingVM } from "@screens/Leasing/LeasingVM";
import SizedBox from "@components/SizedBox";
import Lease from "@screens/Leasing/Lease";
import Cancel from "@screens/Leasing/Cancel";
import Text from "@components/Text";

interface IProps { }

const StakeUnstake: React.FC<IProps> = () => {
  const vm = useLeasingVM();
  return (
    <>
      <Text weight={500} type="secondary">Leasing</Text>
      <SizedBox height={8} />
      <Card>
        <SwitchButtons
          values={["Lease", "Cancel"]}
          active={vm.action}
          onActivate={vm.setAction}
          border
        />
        <SizedBox height={24} />
        {vm.action === 0 ? <Lease /> : <Cancel />}
      </Card>
    </>
  );
};
export default observer(StakeUnstake);
