// eslint-disable-next-line
export default {
  icons: {
    logo: require("@src/assets/darkLogo.svg").default,
    arrowDown: require("@src/assets/icons/darkArrowDown.svg").default,
    limitOrderArrow: require("@src/assets/icons/darkLimitOrderArrow.svg").default,
    arrowDownWithBorder: require("@src/assets/icons/darkArrowWithBorder.svg").default,
    reset: require("@src/assets/icons/darkReset.svg").default,
  }
};
