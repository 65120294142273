import React from "react";
import SizedBox from "@components/SizedBox";
import { Row } from "@components/Flex";
import Text from "@components/Text";
import Button from "@components/Button";
import { useLeasingVM } from "@screens/Leasing/LeasingVM";
import TokenInput from "@components/TokenInput/TokenInput";
import { observer } from "mobx-react-lite";
import Loading from "@components/Loading";
import { useStores } from "@src/stores";

interface IProps { }

const Lease: React.FC<IProps> = () => {
  const vm = useLeasingVM();
  const { accountStore } = useStores();
  return (
    <>
      <TokenInput {...vm.tokenStakeInputInfo} />
      <SizedBox height={24} />
      <Row alignItems="center" justifyContent="space-between">
        <Text type="secondary">Transaction fee</Text>
        <Text textAlign="right">0.001 WAVES</Text>
      </Row>
      <SizedBox height={24} />
      {!vm.loading ? (

        accountStore.address === null ? (<Button
          fixed
          size="large"
          onClick={() => accountStore.setLoginModalOpened(true)}
        >
          Connect wallet
        </Button>) : (
          <Button fixed onClick={vm.lease} disabled={!vm.canLease}>
            {accountStore.address === null ? "Connect wallet" : "Lease Waves"}
          </Button>
        )
      ) : (
        <Button disabled fixed>
          Transaction in progress <Loading />
        </Button>
      )}
    </>
  );
};
export default observer(Lease);
