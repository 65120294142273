"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var random_1 = require("./random");
var encryption_1 = require("./encryption");
var base_xx_1 = require("../conversions/base-xx");
var string_bytes_1 = require("../conversions/string-bytes");
var concat_split_1 = require("./concat-split");
var hashing_1 = require("./hashing");
var address_keys_seed_1 = require("./address-keys-seed");
var sign_1 = require("./sign");
var verification_1 = require("./verification");
var rsa_1 = require("./rsa");
var seed_ecryption_1 = require("./seed-ecryption");
var merkle_verify_1 = require("./merkle-verify");
exports.crypto = function (options) {
    if (options && options.seed == '')
        throw new Error('Empty seed is not allowed.');
    var c = function (f, first) {
        return function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return f.apply(void 0, __spread([first], args));
        };
    };
    var toOut = function (f) { return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var r = f.apply(void 0, __spread(args));
        return (!options || options && options.output === 'Base58') ? base_xx_1.base58Encode(r) : r;
    }; };
    var toOutKey = function (f) { return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var r = f.apply(void 0, __spread(args));
        return ((!options || options && options.output === 'Base58') ?
            ({ privateKey: base_xx_1.base58Encode(r.privateKey), publicKey: base_xx_1.base58Encode(r.publicKey) }) :
            r);
    }; };
    var s = (options && options.seed) ? options.seed : undefined;
    var seedPart = {
        seedWithNonce: s ? c(address_keys_seed_1.seedWithNonce, s) : address_keys_seed_1.seedWithNonce,
        signBytes: toOut(s ? c(sign_1.signBytes, s) : sign_1.signBytes),
        keyPair: toOutKey(s ? c(address_keys_seed_1.keyPair, s) : address_keys_seed_1.keyPair),
        publicKey: toOut(s ? c(address_keys_seed_1.publicKey, s) : address_keys_seed_1.publicKey),
        privateKey: toOut(s ? c(address_keys_seed_1.privateKey, s) : address_keys_seed_1.privateKey),
        address: toOut(s ? c(address_keys_seed_1.address, s) : address_keys_seed_1.address),
    };
    return __assign({}, seedPart, { sharedKey: toOut(encryption_1.sharedKey), buildAddress: address_keys_seed_1.buildAddress,
        blake2b: hashing_1.blake2b,
        keccak: hashing_1.keccak,
        sha256: hashing_1.sha256,
        base64Encode: base_xx_1.base64Encode,
        base64Decode: base_xx_1.base64Decode,
        base58Encode: base_xx_1.base58Encode,
        base58Decode: base_xx_1.base58Decode,
        base16Encode: base_xx_1.base16Encode,
        base16Decode: base_xx_1.base16Decode,
        stringToBytes: string_bytes_1.stringToBytes,
        bytesToString: string_bytes_1.bytesToString,
        random: random_1.random,
        randomSeed: random_1.randomSeed,
        randomBytes: random_1.randomBytes,
        verifySignature: verification_1.verifySignature,
        verifyPublicKey: verification_1.verifyPublicKey,
        verifyAddress: verification_1.verifyAddress,
        messageDecrypt: encryption_1.messageDecrypt,
        messageEncrypt: encryption_1.messageEncrypt,
        aesDecrypt: encryption_1.aesDecrypt,
        aesEncrypt: encryption_1.aesEncrypt,
        encryptSeed: seed_ecryption_1.encryptSeed,
        decryptSeed: seed_ecryption_1.decryptSeed,
        rsaKeyPair: rsa_1.rsaKeyPair,
        rsaKeyPairSync: rsa_1.rsaKeyPairSync,
        rsaSign: rsa_1.rsaSign,
        rsaVerify: rsa_1.rsaVerify,
        merkleVerify: merkle_verify_1.merkleVerify,
        split: concat_split_1.split,
        concat: concat_split_1.concat });
};
