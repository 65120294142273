"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var param_1 = require("../conversions/param");
exports.concat = function () {
    var arrays = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        arrays[_i] = arguments[_i];
    }
    return arrays.reduce(function (a, b) { return Uint8Array.from(__spread(a, param_1._fromIn(b))); }, new Uint8Array(0));
};
exports.split = function (binary) {
    var sizes = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        sizes[_i - 1] = arguments[_i];
    }
    var _a = sizes.reduce(function (a, s) { return ({ arr: a.arr.slice(s), r: __spread(a.r, [a.arr.slice(0, s)]) }); }, { arr: param_1._fromIn(binary), r: [] }), r = _a.r, arr = _a.arr;
    return __spread(r, [arr]);
};
