"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var param_1 = require("../conversions/param");
var sha3_1 = require("../libs/sha3");
// @ts-ignore
var forgeHmac = __importStar(require("node-forge/lib/hmac"));
// @ts-ignore
var forgeMd = __importStar(require("node-forge/lib/md"));
require("node-forge/lib/sha256");
var blake = __importStar(require("../libs/blake2b"));
var string_bytes_1 = require("../conversions/string-bytes");
exports._hashChain = function (input) {
    return param_1._fromIn(exports.keccak(exports.blake2b(param_1._fromIn(input))));
};
exports.sha256 = function (input) {
    var md = forgeMd.algorithms.sha256.create();
    md.update(string_bytes_1.bytesToString(input, 'raw'));
    return string_bytes_1.stringToBytes(md.digest().getBytes(), 'raw');
};
exports.blake2b = function (input) {
    return blake.blake2b(param_1._fromIn(input), null, 32);
};
exports.keccak = function (input) {
    return param_1._fromIn(sha3_1.keccak256.array(param_1._fromIn(input)));
};
exports.hmacSHA256 = function (message, key) {
    var hmac = forgeHmac.create();
    hmac.start('sha256', string_bytes_1.bytesToString(param_1._fromIn(key), 'raw'));
    hmac.update(string_bytes_1.bytesToString(param_1._fromIn(message), 'raw'));
    return string_bytes_1.stringToBytes(hmac.digest().getBytes(), 'raw');
};
