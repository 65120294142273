import { makeAutoObservable } from "mobx";
import NotificationStore from "@stores/NotificationStore";
import AccountStore, { ISerializedAccountStore } from "@stores/AccountStore";
import OverviewStore from "./OverviewStore";
import SettingsStore from "./SettingsStore";
import PricesStore from "./PricesStore";
import VotingStore from "./VotingStore";

export interface ISerializedRootStore {
  accountStore?: ISerializedAccountStore;
}

export default class RootStore {
  public accountStore: AccountStore;
  public overviewStore: OverviewStore;
  public notificationStore: NotificationStore;
  public settingsStore: SettingsStore;
  public pricesStore: PricesStore;
  public votingStore: VotingStore;

  constructor(initState?: ISerializedRootStore) {
    this.notificationStore = new NotificationStore(this);
    this.accountStore = new AccountStore(this, initState?.accountStore);
    this.overviewStore = new OverviewStore(this);
    this.settingsStore = new SettingsStore(this);
    this.pricesStore = new PricesStore(this);
    this.votingStore = new VotingStore(this);
    makeAutoObservable(this);
  }

  serialize = (): ISerializedRootStore => ({
    accountStore: this.accountStore.serialize(),
  });
}
